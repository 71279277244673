export const pushCustomSubmitEvent = formID =>
  window.dataLayer.push({
    event: 'custom_submit_true',
    category: 'custom_submit_true',
    'gtm.elementId': `url: ${window.location.href}; formID: ${formID};`,
  });

export const pushABTestEvent = (formName, isFormWithSchedule, isBookTime) => {
  const action = isFormWithSchedule
    ? 'submit_form_with_scheduled_call'
    : 'submit_form_without_scheduled_call';
  const label = isFormWithSchedule ? `${formName}; with Book a call: ${isBookTime}` : formName;

  return window.dataLayer.push({
    event: 'ndrsn_form_schedule',
    action,
    label,
  });
};

export const pushCookiePolicyEvent = () =>
  window.dataLayer.push({
    event: 'cookie_policy_update',
  });

export const pushCustomEvent = (event, options = {}) => {
  window.dataLayer.push({
    event,
    ...options,
  });
};

import React from 'react';
import { Grid, GridItem } from 'components/UI';
import { useTrans } from 'utils/localisation';
import { useCardsLayout } from './hooks';

import * as styles from './PreviewCardsGallery.module.scss';

export default function PreviewCardsGallery({
  pageSize = 3,
  withShowMore = true,
  CardComponent,
  items,
  page,
  gridConfig,
  ...props
}) {
  const t = useTrans(['common']);

  const { count, showMore, checkCardWidth, checkHide } = useCardsLayout(
    pageSize,
    items?.length,
    page,
  );

  const defaultGridConfig = {
    xsm: { cols: 1, gutterX: '30px', gutterY: '60px' },
    sm: { cols: 1, gutterX: '30px', gutterY: '60px' },
    md: { cols: 2, gutterX: '40px', gutterY: '50px' },
    lg: { cols: 2, gutterX: '50px', gutterY: '90px' },
    xl: { cols: 2, gutterX: '50px', gutterY: '90px' },
  };

  const gridSettings = gridConfig ?? defaultGridConfig;

  return (
    <div className={styles.items}>
      <div dir="ltr">
        <Grid {...gridSettings}>
          {items?.map((item, i) => {
            const cardWidth = checkCardWidth(i, items.length);
            const hide = checkHide(i + 1);

            return (
              <GridItem key={item.id} colSpan={cardWidth === 'lgWidth' ? 2 : undefined} hide={hide}>
                <CardComponent {...item} theme={cardWidth} hidden={hide} {...props} />
              </GridItem>
            );
          })}
        </Grid>
      </div>
      {withShowMore && count < items?.length && (
        <div className={styles.showRow}>
          <button onClick={showMore} className={styles.seeMore}>
            {`${t('seeMore')} (${items?.length - count})`}
          </button>
        </div>
      )}
    </div>
  );
}
